import './Home.css';
import Layout from "../Layout/Layout";
import Panel from "../Panel/Panel";
import banner_glitchDroplet from "./../../Images/banner_glitchDroplet.png"
import {Helmet} from "react-helmet";
import React from "react";

function Home() {
    return (
        <>
            <Helmet>
                <meta charSet={'utf-8'}/>
                <title>EnkiTheMystik</title>
                <link rel={"canonical"} href={'https://www.enkithemystik.com/'}/>
            </Helmet>
            <Layout >
                <Panel content={
                    <img src={banner_glitchDroplet} style={{width: '100%', position: 'fixed', left:'0', top: '60px' }}   alt={'banner_glitchDroplet'}/>
                }  backgroundColor={'black'} columnStart={1} columnEnd={10}   />
                <Panel content={
                   <>
                       <h1 style={{color: 'white', margin: 50, padding: 0}}>Welcome</h1>
                       <h2 style={{color: 'white'}}>Site under construction...</h2>
                   </>
                }  position={'relative'} columnStart={1} columnEnd={10} rowStart={1} backgroundColor={'#FF00DD'}  />

                <Panel content={
                    <p style={{color: 'white', margin: '50px', marginTop: '90px' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                                    Pellentesque nec nam aliquam sem et tortor consequat id porta. Elementum nibh tellus molestie nunc non blandit massa enim.
                                                    Pretium lectus quam id leo in vitae. Fusce ut placerat orci nulla pellentesque. Risus nullam eget felis eget nunc lobortis
                                                    mattis aliquam faucibus. Molestie nunc non blandit massa. Non odio euismod lacinia at quis risus. Adipiscing elit pellentesque
                                                    habitant morbi tristique senectus et netus. Aliquet sagittis id consectetur purus ut faucibus pulvinar elementum. Dolor purus
                                                    non enim praesent elementum. Vitae tortor condimentum lacinia quis vel eros donec. Ultrices vitae auctor eu augue.</p>
                } position={'relative'} fontColor={'white'} backgroundColor={'black'} columnStart={3} columnEnd={8} rowStart={3}  borderRadius={'10px'} margin={'0px'} />

                <Panel content={
                    <></>
                } backgroundColor={'#FF00DD'} position={'relative'} columnStart={1} columnEnd={10} rowStart={4} margin={'50px'}/>
                <Panel content={
                    <p style={{color: 'white', margin: '50px', marginTop: '90px' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                        Pellentesque nec nam aliquam sem et tortor consequat id porta. Elementum nibh tellus molestie nunc non blandit massa enim.
                        Pretium lectus quam id leo in vitae. Fusce ut placerat orci nulla pellentesque. Risus nullam eget felis eget nunc lobortis
                        mattis aliquam faucibus. Molestie nunc non blandit massa. Non odio euismod lacinia at quis risus. Adipiscing elit pellentesque
                        habitant morbi tristique senectus et netus. Aliquet sagittis id consectetur purus ut faucibus pulvinar elementum. Dolor purus
                        non enim praesent elementum. Vitae tortor condimentum lacinia quis vel eros donec. Ultrices vitae auctor eu augue.</p>
                } position={'relative'} fontColor={'white'} backgroundColor={'black'} columnStart={3} columnEnd={8} rowStart={5}  borderRadius={'10px'} margin={'0px'} />
                <Panel content={
                    <h1 style={{color: 'white'}}></h1>
                } columnStart={1} columnEnd={10} rowStart={6} backgroundColor={'#FF00DD'} margin={'60px'} position={'sticky'}/>



                <Panel content={
                    <>
                        <h1 style={{color: 'white'}}>EnkiTheMystik</h1>
                    </>
                } columnStart={1} columnEnd={10}  backgroundColor={'black'} padding={'0px'} position={'sticky'} width={'100%'}/>


            </Layout>

        </>
    )
}

export default Home;









