import './Header.css'

function Header({color, textColor, fontSize, padding, position}) {
    return(
        <header className='Header' style={{backgroundColor:color,
            color:textColor, fontSize: fontSize, padding: padding, position: position }}>

        </header>
    )
}

export default Header;
