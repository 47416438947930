function Panel ({content, columnStart, columnEnd, rowStart, rowEnd,
                    padding, margin, width, fontSize, fontColor, backgroundColor, position, borderRadius, display }) {
    return (
        <div style={{gridColumnStart:columnStart, gridColumnEnd:columnEnd, gridRowStart: rowStart,
                    gridRowEnd: rowEnd, padding: padding, margin: margin, width: width, fontSize: fontSize,
                    fontColor: fontColor, backgroundColor: backgroundColor, position: position, borderRadius: borderRadius, display: display }}>
            {content}
        </div>
    )
}

export default Panel;
