import React from "react";
import './App.css';
import { Route } from "react-router-dom";
import Home  from "./Components/Pages/Home"
import Header from "./Components/Header/Header"
import SideBar from "./Components/SideBar/SideBar";
import {Helmet} from "react-helmet";
function App() {
    document.title = "EnkiTheMystik";

  return (
    <div className="App">
        <Header color={'black'} padding={'30px'} position={'relative'} />
        <Helmet>
            <meta charSet={'utf-8'}/>
            <title>EnkiTheMystik</title>
            <link rel={"canonical"} href={'https://www.enkithemystik.com/'}/>
        </Helmet>
      <switch>
        <Route exact path="/" component={Home}/>
      </switch>
    </div>
  );
}

export default App;
